window._ = require('lodash');
try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('datatables.net');
} catch (e) {}
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/autocomplete.js';
import 'jquery-ui/ui/widgets/accordion.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'select2/dist/js/select2.min.js';
import 'select2/src/js/select2/i18n/es.js';
import 'sweetalert2/dist/sweetalert2.min.js';
